// Redirector.js
import React, { useEffect } from "react";

const Redirector = ({ children }) => {
  useEffect(() => {
    // Check if the current URL is not using HTTPS or lacks the www subdomain
    if (
      (window.location.protocol !== "https:" ||
        !window.location.hostname.startsWith("www.")) &&
      !window.location.hostname.includes("localhost")
    ) {
      let subdomain = "";
      if (!window.location.hostname.startsWith("www.")) {
        subdomain = "www.";
      }
      const newURL = `https://${subdomain}${window.location.hostname}${window.location.pathname}${window.location.search}${window.location.hash}`;
      window.location.href = newURL;
    }
  }, []);

  return <>{children}</>;
};

export default Redirector;
