import React, { useState } from "react";
import { GoogleLogin } from "@react-oauth/google";
import axios from "axios";
import { ResponseCodes, getApiUrl } from "../utils/network-utils";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../context/AuthContext";
import { getCookieValue } from "../utils/cookie-utils";
import { Button, Snackbar, Alert } from "@mui/material";
import GoogleIcon from "./GoogleIcon";

const SignInWithGoogle = () => {
  const navigate = useNavigate();
  const { login } = useAuth();
  const [googleIdToken] = useState(getCookieValue("google_id_token"));
  const [errorMessage, setErrorMessage] = useState(null); // New state for the error message
  const [openSnackbar, setOpenSnackbar] = useState(false); // Controls Snackbar visibility

  // Custom button styles to mimic Google button appearance
  const googleButtonStyle = {
    backgroundColor: "#ffffff",
    color: "#3c4043",
    border: "1px solid #ddd",
    borderRadius: "4px",
    fontSize: "14px",
    fontWeight: "500",
    fontFamily: "'Roboto', arial, sans-serif",
    letterSpacing: "0.25px",
    lineHeight: "21px",
    margin: 0,
    paddingTop: "10px",
    paddingBottom: "10px",
    paddingLeft: "30px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
    cursor: "pointer",
    gap: "10px",
    textTransform: "none",
    width: "100%",
  };
  const googleIconStyle = {
    height: "20px",
    width: "20px",
    position: "absolute", // Fixes the icon position
    left: "10px", // Adjusts distance from the left edge of the button
  };

  const handleBackendLogin = async (idToken) => {
    const apiUrl = getApiUrl() + "auth/google";

    try {
      const response = await axios.post(
        apiUrl,
        { credential: idToken },
        { withCredentials: true }
      );

      if (response?.data?.status_code === ResponseCodes.SUCCESS) {
        login(); // Set the user as logged in
        navigate("/search");
      } else {
        //console.log("Login error:", response.data);
        setErrorMessage("Google login failed"); // Show error message
        setOpenSnackbar(true); // Open Snackbar
      }
    } catch (error) {
      //console.error("Backend error:", error);
      setErrorMessage("Google login failed"); // Show error message
      setOpenSnackbar(true); // Open Snackbar
    }
  };

  const handleSuccess = (response) => {
    // Google OAuth flow succeeded; call backend with the new ID token
    handleBackendLogin(response.credential);
  };

  const handleFailure = (error) => {
    console.log("Error:", error);
    // Handle error if authentication fails
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  return (
    <div>
      {/* Snackbar for error message */}
      <Snackbar
        open={openSnackbar}
        autoHideDuration={3000} // Hide after 3 seconds
        onClose={handleCloseSnackbar}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity="error" // Use MUI Alert component for error styling
          sx={{ width: "100%" }}
        >
          {errorMessage}
        </Alert>
      </Snackbar>
      {googleIdToken ? (
        // Custom button that looks like the Google login button
        <Button
          onClick={() => handleBackendLogin(googleIdToken)}
          style={googleButtonStyle}
        >
          <GoogleIcon style={googleIconStyle} />
          Sign in with Google
        </Button>
      ) : (
        <GoogleLogin onSuccess={handleSuccess} onError={handleFailure} useOneTap />
      )}
    </div>
  );
};

export default SignInWithGoogle;
