import React, { useEffect } from "react";
import { Typography, Paper } from "@mui/material";
import ReactGA from "react-ga4";
import useCustomStyles from "../styles/customStyles";

function Contact(props) {
  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname + window.location.search,
      title: "Contact",
    });
  }, []);
  const customClasses = useCustomStyles();
  return (
    <div className={customClasses.root}>
      <Paper className={customClasses.headingPaper}>
        <div>
          <Typography variant="heading1">Contact</Typography>
        </div>
        <Typography variant="bodyText">
          If you would like to contact the maintainers of this website, please send an
          email to{" "}
          <a href="mailto:updates@ceylontamils.com?subject=CeylonTamils.com%20Update&body=Your%20Message">
            updates@ceylontamils.com
          </a>
          .
        </Typography>
      </Paper>
    </div>
  );
}

export default Contact;
