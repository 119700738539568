import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { getApiUrl } from "../utils/network-utils";
import ReactGA from "react-ga4";
import { useAuth } from "../context/AuthContext";
import { clearTokenCookies } from "../utils/cookie-utils";

const Logout = () => {
  const navigate = useNavigate();
  const { logout } = useAuth();

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname + window.location.search,
      title: "Logout",
    });

    const handleLogout = () => {
      try {
        // Send a logout request to the backend
        const logoutUrl = getApiUrl() + "auth/logout";

        //console.log("Calling API: " + logoutUrl);
        axios
          .get(logoutUrl, {
            withCredentials: true, // Enable sending cookies
          })
          .then((response) => {
            clearTokenCookies();
            //console.log("Logout response: " + JSON.stringify(response));
          })
          .catch((error) => {
            clearTokenCookies();
            //console.error("Error fetching data:", error);
          });

        // Update state in auth context to logged out
        logout();

        // Redirect to the login page
        navigate("/login");
      } catch (error) {
        console.error("Logout failed:", error);
        // Handle any error during the logout process
      }
    };

    // Call the logout function when the component mounts
    handleLogout();
  }, [navigate, logout]); // Empty dependency array ensures the effect runs only once on mount

  // An empty div or null can be returned, as nothing needs to be rendered
  return null;
};

export default Logout;
