import React, { useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import { grey } from "@mui/material/colors";
import { useNavigate } from "react-router-dom";
import {
  Paper,
  TextField,
  Button,
  InputAdornment,
  IconButton,
  CircularProgress,
  FormHelperText,
} from "@mui/material";
import { Visibility, VisibilityOff, Email, Lock } from "@mui/icons-material";
import axios from "axios";
import { getApiUrl, ResponseCodes } from "../utils/network-utils";
import { AuthNavigation, useAuth } from "../context/AuthContext";
import SignInWithGoogle from "../auth/SignInWithGoogle";
import { GoogleOAuthProvider } from "@react-oauth/google";
import ReactGA from "react-ga4";

// -----------------

const useStyles = makeStyles((theme) => ({
  root: {
    color: grey[500],
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  loginButton: {
    marginTop: "1rem",
  },
}));

const LoginPage = (props) => {
  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname + window.location.search,
      title: "Login",
    });
    // Access the state passed from the ProtectedRoute
  }, []);

  // get login state management function from auth context
  const { login } = useAuth();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [loginError, setLoginError] = useState("");
  const classes = useStyles();
  const apiUrl = getApiUrl() + "auth/login";
  const GOOGLE_CLIENT_ID =
    "667127263796-cergvegieoen1s6sro9hhv0vu1sm95bo.apps.googleusercontent.com";

  const validateEmail = (inputEmail) => {
    const emailRegex =
      /^[a-zA-Z0-9]+[a-zA-Z0-9.+_-]*@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-][a-zA-Z0-9-]+)+$/;
    return emailRegex.test(inputEmail);
  };

  const validatePassword = (inputPassword) => {
    return inputPassword.length >= 8;
  };

  const handleEmailChange = (e) => {
    const newEmail = e.target.value;
    setEmail(newEmail);
    if (validateEmail(newEmail)) {
      setEmailError("");
    } else {
      setEmailError("Invalid email");
    }
  };

  // Function to handle change in password input field
  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    setPassword(newPassword);
    if (validatePassword(newPassword)) {
      setPasswordError("");
    } else {
      setPasswordError("Invalid password");
    }
  };

  // Function to handle return key press in password input field
  const onKeyPress = (event) => {
    if (event.keyCode === 13 && validateEmail(email) && validatePassword(password)) {
      handleLogin();
    }
  };
  const navigate = useNavigate();
  // --------------------------------------------
  // Function to handle login call to server
  const handleLogin = async () => {
    setLoading(true);

    try {
      const params = new URLSearchParams([
        ["email", email],
        ["password", password],
      ]);

      //console.log("Calling " + apiUrl);
      const response = await axios.post(apiUrl, params, {
        withCredentials: true, // Enable sending cookies
      });

      //console.log(response.data);

      // Assuming the response structure is as described
      if (response.data.status_code === ResponseCodes.SUCCESS) {
        // Login SUCCESS
        // set state as logged in, in auth context
        login();

        //console.log("Attempting to navigate to " + AuthNavigation.getRequestedPath());
        navigate(AuthNavigation.getRequestedPath());
        AuthNavigation.clearRequestedPath();
      } else {
        setLoginError(ResponseCodes.getMessage(response.data.status_code));
      }
    } catch (error) {
      console.error("Error during login:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={classes.root}>
      <Paper
        elevation={3}
        style={{
          padding: "20px",
          minWidth: "300px",
          maxWidth: "400px",
          marginBottom: "2rem",
        }}
      >
        {/* Your logo here */}
        <TextField
          label="Email Address"
          placeholder="Enter email"
          fullWidth
          margin="normal"
          variant="outlined"
          value={email}
          onKeyDown={onKeyPress}
          onChange={handleEmailChange}
          error={Boolean(emailError)}
          helperText={emailError}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Email />
              </InputAdornment>
            ),
          }}
          autoFocus
        />
        <TextField
          label="Password"
          placeholder="Enter password"
          fullWidth
          margin="normal"
          variant="outlined"
          type={showPassword ? "text" : "password"}
          value={password}
          onKeyDown={onKeyPress}
          onChange={handlePasswordChange}
          error={Boolean(passwordError)}
          helperText={passwordError}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Lock />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <Button
          className={classes.loginButton}
          variant="contained"
          color="primary"
          fullWidth
          onClick={handleLogin}
          disabled={loading || Boolean(emailError) || Boolean(passwordError)}
        >
          {loading ? <CircularProgress size={24} /> : "Login"}
        </Button>
        {loginError && <FormHelperText error>{loginError}</FormHelperText>}
      </Paper>
      <Paper
        elevation={3}
        style={{
          padding: "20px",
          minWidth: "300px",
          maxWidth: "400px",
        }}
      >
        <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
          <SignInWithGoogle />
        </GoogleOAuthProvider>
      </Paper>
    </div>
  );
};

export default LoginPage;
