import { makeStyles } from "@mui/styles";
import { grey } from "@mui/material/colors";

const useCustomStyles = makeStyles((theme) => ({
  root: {
    color: grey[500],
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  headingPaper: {
    width: "100%",
    padding: theme.spacing(3),
    marginBottom: theme.spacing(3),
    background: "url('../images/cover.jpg') center/cover no-repeat",
    backgroundColor: "#FFF",
  },
  contentPaper: {
    width: "100%",
    justifyContent: "center",
    padding: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
}));

export default useCustomStyles;
