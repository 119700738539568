import React from "react";
import { Dialog, DialogContent, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ReactGA from "react-ga4";

const PhotoModal = ({ open, onClose, photoUrl }) => {
  //console.log("Modal Photo url: " + photoUrl);
  ReactGA.event({
    category: "timeline-action",
    action: "click-view-photo-button",
    label: "view-photo", // optional
  });

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogContent>
        <div style={{ position: "relative" }}>
          <IconButton
            edge="end"
            color="inherit"
            onClick={onClose}
            sx={{ position: "absolute", top: 0, right: 0 }}
          >
            <CloseIcon />
          </IconButton>
          <img
            src={photoUrl}
            alt="an individual"
            style={{ width: "100%", maxHeight: "80vh", objectFit: "contain" }}
          />
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default PhotoModal;
