// AuthContext.js
import React, { createContext, useContext, useReducer, useEffect } from "react";
import { jwtDecode } from "jwt-decode";
import { getAccessToken } from "../utils/cookie-utils";

const AuthContext = createContext();

const initialState = {
  isAuthenticated: getAccessToken(),
  user: null,
};

// Reducer used to manage state
const reducer = (state, action) => {
  switch (action.type) {
    case "LOGIN":
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload,
      };
    case "LOGOUT":
      return {
        ...state,
        isAuthenticated: false,
        user: null,
      };
    default:
      return state;
  }
};

const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    login();
  }, []);

  const login = () => {
    const authToken = getAccessToken();
    if (authToken) {
      // Decode the JWT token to extract user information
      const decodedToken = jwtDecode(authToken);
      const user = {
        email: decodedToken.email,
        firstName: decodedToken.firstName,
        lastName: decodedToken.lastName,
        roles: decodedToken.roles,
      };

      //console.log("Roles: " + user.roles);
      //console.log("First Name: " + user.firstName);
      //console.log("Last Name: " + user.lastName);

      dispatch({ type: "LOGIN", payload: user });
    }
  };

  const logout = () => {
    dispatch({ type: "LOGOUT" });
    //clearTokenCookies();

    // Reset the currentPath to the default value upon logout
    dispatch({ type: "SET_CURRENT_PATH", payload: "/dashboard" });
  };

  // Return the state and additional properties
  const contextValue = {
    state,
    login,
    logout,
    isAuthenticated: state.isAuthenticated,
    user: state.user,
  };

  return <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>;
};

const useAuth = () => useContext(AuthContext);

class AuthNavigation {
  static DEFAULT_PATH = "/dashboard";
  static requestedPath = this.DEFAULT_PATH;

  static setRequestedPath(path) {
    this.requestedPath = path;
  }

  static getRequestedPath() {
    return this.requestedPath;
  }

  static clearRequestedPath() {
    this.requestedPath = this.DEFAULT_PATH;
  }
}

export { AuthProvider, useAuth, AuthNavigation };
