import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Typography, Paper } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { grey } from "@mui/material/colors";
import ReactGA from "react-ga4";
import useCustomStyles from "../styles/customStyles";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    color: grey[500],
  },
  section: {
    width: "95%",
    padding: theme.spacing(3),
    marginBottom: theme.spacing(3),
    background: "url('images/cover.jpg') center/cover no-repeat",
    backgroundColor: "#FFF",
  },
}));

function NotFound() {
  const navigate = useNavigate();

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname + window.location.search,
      title: "PageNotFound",
    });
    // Redirect to the homepage after a delay of 5 seconds
    const redirectTimeout = setTimeout(() => {
      navigate("/");
    }, 5000);

    // Clear the timeout if the component is unmounted
    return () => clearTimeout(redirectTimeout);
  }, [navigate]);

  const classes = useStyles();
  const customClasses = useCustomStyles();

  return (
    <div className={classes.root}>
      <Paper className={customClasses.headingPaper}>
        <Typography variant="h4">Page Not Found</Typography>
        <Typography className="bodyText">
          This website has been redesigned and you may be trying to access a page that
          does not exist. If you are not redirected,{" "}
          <a href="https://www.ceylontamils.com">click here</a> to go to the homepage.
        </Typography>
      </Paper>
    </div>
  );
}

export default NotFound;
