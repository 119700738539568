import React, { useEffect } from "react";
import { Typography, Paper } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { grey } from "@mui/material/colors";
import ReactGA from "react-ga4";
import useCustomStyles from "../styles/customStyles";

const useStyles = makeStyles((theme) => ({
  root: {
    color: grey[500],
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  searchSection: {
    width: "95%",
  },
}));

function Notes() {
  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname + window.location.search,
      title: "Notes",
    });
    // eslint-disable-next-line
  }, []);
  const classes = useStyles();
  const customClasses = useCustomStyles();

  return (
    <div className={classes.root}>
      <Paper className={customClasses.headingPaper}>
        <div>
          <Typography variant="heading1">A Note on Tamil Titles</Typography>
        </div>
        <Typography variant="bodyText">
          For ease of indexing, and in order to standardise the format for names, this
          website uses a hybrid name structure which is not strictly correct. In order to
          construct names in an accurate Tamil order, it may be necessary to place prefix
          and suffix titles in a different location in relation to an individual's names
          than those presented on particular family pages. All titles in the database are
          prefix titles (Sir, Don, Mudaliyar, Rajakesari, etc.,). Traditional Tamil titles
          were suffix titles. Thus, the full name of Sir Muttukumarasamy, son of Gate
          Mudaliyar Coomarasamy, son of Arumugampillai, should be written as
          Arumugampillai Coomarasamy Gate Mudaliyar Sir Muttukumarasamy. This formulation
          is potentially confusing and all titles are represented as prefix titles to
          limit this. Suffix titles on the database are restricted to post-nominal letters
          (MBE, OBE, MVO, etc.,) and the village of origin of the person described.
        </Typography>
        <br />
        <br />
        <div>
          <Typography variant="heading2">ADC</Typography>{" "}
        </div>
        <Typography variant="bodyText">
          Aide de Camp. Military honorary appointment of chief military aide or assistant.
          Use of the letters after a name after conclusion of the appointment indicates
          that the individual was aide de camp to a Head of State.
        </Typography>
        <div>
          <Typography variant="heading2">Arachchiyar</Typography>{" "}
        </div>
        <Typography variant="bodyText">
          Tamil title, used for judges and representatives of the kin
        </Typography>
        <div>
          <Typography variant="heading2">BCE</Typography>{" "}
        </div>
        <Typography variant="bodyText">
          Before the Current Era (corresponds to Before Christ - BC - in the Gregorian
          Calendar)
        </Typography>
        <div>
          <Typography variant="heading2">CE</Typography>{" "}
        </div>
        <Typography variant="bodyText">
          Current Era (corresponds to Anno Domini - AD - in the Gregorian Calendar)
        </Typography>
        <div>
          <Typography variant="heading2">Chaddampiar</Typography>{" "}
        </div>
        <Typography variant="bodyText">Tamil title, meaning teacher</Typography>{" "}
        <div>
          <Typography variant="heading2">Chakravarti</Typography>{" "}
        </div>
        <Typography variant="bodyText">Emperor</Typography>
        <div>
          <Typography variant="heading2">Dom</Typography>{" "}
        </div>
        <Typography variant="bodyText">see Don</Typography>
        <div>
          <Typography variant="heading2">Don (also Dom)</Typography>{" "}
        </div>
        <Typography variant="bodyText">
          In local usage, an inherited Portuguese title, equating to knighthood in the
          British Imperial system (both hereditary and appointed). Early Portuguese
          administrators used the title 'Dom' to refer to Sri Lankans of noble descent,
          granted Sri Lankans the title as a courtesy when they were appointed to local
          administrative positions and also allowed Sri Lankans to purchase hereditary use
          of the title. Under the Dutch and British, this was commonly replaced by 'Don'
        </Typography>
        <div>
          <Typography variant="heading2">ED</Typography>{" "}
        </div>
        <Typography variant="bodyText">
          Efficiency Decoration (Sri Lanka army medal)
        </Typography>
        <div>
          <Typography variant="heading2">Gate Mudaliyar</Typography>{" "}
        </div>
        <Typography variant="bodyText">see Mudaliyar</Typography>
        <div>
          <Typography variant="heading2">Irumarapuntuyar</Typography>{" "}
        </div>
        <Typography variant="bodyText">
          Title associated with Jaffna royalty. Possibly combination of 'Rama' and
          'Pandya' - i.e. a reference to Rameswaram, the home of Vijeya Kulangkai Ariyar
          Chakravarti, and the Pandyan descent of the Ariyar Chakravarti kings. If this is
          correct, a more familiar contemporary transliteration of the title might be
          something like 'Ramapandya' - at least two roughly contemporaneous Pandyan kings
          of the late Pandyan period (Varathungga Ramapandya -1588 CE and Adhi Vira
          Ramapandya -1564-1604 CE) used this title. This is after the Portuguese arrived
          in Lanka and before the Dutch took over in the late 1600s.
        </Typography>
        <div>
          <Typography variant="heading2">Gate Mudaliyar</Typography>{" "}
        </div>
        <Typography variant="bodyText">see Mudaliyar</Typography>
        <div>
          <Typography variant="heading2">Kovil</Typography>{" "}
        </div>
        <Typography variant="bodyText">Temple</Typography>
        <div>
          <Typography variant="heading2">Kumar (also Kumaran)</Typography>{" "}
        </div>
        <Typography variant="bodyText">Prince</Typography>
        <div>
          <Typography variant="heading2">Maha Mudaliyar</Typography>{" "}
        </div>
        <Typography variant="bodyText">see Mudaliyar</Typography>
        <div>
          <Typography variant="heading2">Maharajah</Typography>{" "}
        </div>
        <Typography variant="bodyText">see Rajah</Typography>
        <div>
          <Typography variant="heading2">Maniagar</Typography>{" "}
        </div>
        <Typography variant="bodyText">
          In ancient Tamil culture, the title Maniagar (or Maṇiyāgar) referred to an
          official or administrator responsible for overseeing the affairs of a specific
          territory or village, often as a representative of the king or a local
          chieftain. The term Mani in Tamil can mean "jewel" or something precious, while
          agar or yāgar implies a person in charge, giving the title a sense of prestige
          or honor. The Maniagar typically handled tax collection, land revenue, resource
          management, and maintained law and order within their jurisdiction. This
          position was often conferred upon individuals of high status or wealth,
          reflecting a trusted role within the administration of the kingdom.
        </Typography>
        <div>
          <Typography variant="heading2">MBE</Typography>{" "}
        </div>
        <Typography variant="bodyText">
          Member of the British Empire (British honour)
        </Typography>
        <div>
          <Typography variant="heading2">Mudali</Typography>{" "}
        </div>
        <Typography variant="bodyText">see Mudaliyar</Typography>
        <div>
          <Typography variant="heading2">
            {" "}
            Mudaliyar (also Mudali, Gate Mudaliyar, Maha Mudaliyar)
          </Typography>{" "}
        </div>
        <Typography variant="bodyText">
          The title Mudaliyar (or Mudaliar) holds a significant place in Tamil culture and
          history, both as a title and a social designation. Derived from the Tamil word
          mudal, meaning "first" or "primary," Mudaliyar denotes a person of high rank,
          seniority, or leadership within a community. Historically, it was a title given
          to prominent individuals in Tamil society, often those who held key roles in
          governance, military, or land administration. In the pre-colonial and colonial
          periods, Mudaliyars were influential figures. Under colonial rule, particularly
          by the Portuguese and British in Sri Lanka, the title was institutionalized as
          part of a system of local governance. Mudaliyars served as intermediaries
          between the colonial government and local communities, often managing
          administrative tasks, collecting taxes, and representing colonial interests in
          rural areas. Over time, the title Mudaliyar became hereditary and also turned
          into a respected caste or community name in Tamil society. It’s still used as an
          honorific title and is associated with the legacy of leadership and service to
          the community.
        </Typography>
        <div>
          <Typography variant="heading2">MVO</Typography>{" "}
        </div>
        <Typography variant="bodyText">
          Member of the Royal Victorian Order (British honour)
        </Typography>
        <div>
          <Typography variant="heading2">Notary</Typography>{" "}
        </div>
        <Typography variant="bodyText">
          {" "}
          an officer of the court and the civil administration, capable of receiving oaths
          and witnessing affidavits
        </Typography>
        <div>
          <Typography variant="heading2">OBE</Typography>{" "}
        </div>
        <Typography variant="bodyText">
          Order of the British Empire (British honour)
        </Typography>
        <div>
          <Typography variant="heading2">Parakesari</Typography>{" "}
        </div>
        <Typography variant="bodyText">see Rajakesari</Typography>
        <div>
          <Typography variant="heading2">
            Pararajasekaran (also Segarajasekaran)
          </Typography>{" "}
        </div>
        <Typography variant="bodyText">
          The titles used by the Ariyar Chakravarti Emperors in alternation - similar
          usage was made of the titles rajakesari and parakesari by the Chola Emperors.
        </Typography>
        <div>
          <Typography variant="heading2">Proctor</Typography>{" "}
        </div>
        <Typography variant="bodyText">An officer of the court or an advocate</Typography>
        <div>
          <Typography variant="heading2">Pullavar</Typography>{" "}
        </div>
        <Typography variant="bodyText">Tamil title, meaning poet</Typography>
        <div>
          <Typography variant="heading2">Rajah (also Maharajah)</Typography>{" "}
        </div>
        <Typography variant="bodyText">King</Typography>
        <div>
          <Typography variant="heading2">Rajakesari (also Parakesari)</Typography>{" "}
        </div>
        <Typography variant="bodyText">
          The titles used by the Chola Emperors in alternation.
        </Typography>
        <div>
          <Typography variant="heading2">Segarajasekeran</Typography>{" "}
        </div>
        <Typography variant="bodyText">see Pararajasekaran</Typography>
        <div>
          <Typography variant="heading2">Shroff</Typography>{" "}
        </div>
        <Typography variant="bodyText">an officer of the civil administration</Typography>
        <div>
          <Typography variant="heading2">Udaiyar (or Udayar)</Typography>{" "}
        </div>
        <Typography variant="bodyText">
          In ancient Tamil culture this title carries the meaning of "owner" or "lord,"
          derived from the Tamil word udai, meaning "to possess" or "to have." Those with
          the title Udaiyar were often landowners, chieftains, or nobles, indicating a
          person who had control or possession over a significant amount of land,
          resources, or people. Udaiyars were usually part of the higher social ranks, and
          the title symbolized authority and status, especially in agrarian societies
          where landownership was directly tied to wealth and power. In many cases,
          Udaiyars served as local rulers or influential figures within their territories,
          managing agricultural production, ensuring the security of their lands, and
          maintaining local governance. While the title has historical roots, it still
          carries social significance today in some Tamil communities, particularly among
          those whose families historically held land or held a prominent position in the
          local hierarchy.
        </Typography>
        <div>
          <Typography variant="heading2">Vidane</Typography>{" "}
        </div>
        <Typography variant="bodyText">
          The title Vidane (or Vidanai) originates from Tamil and Sinhalese cultures,
          commonly used in Sri Lanka and South India, and refers to a village headman or
          local official. Vidane is derived from the Sanskrit word vidhana, meaning "to
          govern" or "to administer." This title signified a person responsible for
          overseeing the administration and welfare of a village or a small region, often
          under the larger jurisdiction of a chieftain or regional ruler. A Vidane acted
          as the primary intermediary between the village community and higher
          authorities, handling various administrative duties such as collecting taxes,
          resolving minor disputes, organizing community activities, and ensuring law and
          order. They also managed land distribution, especially in agricultural
          communities, and played a key role in mobilizing labor for communal projects. In
          some regions, Vidane titles were hereditary, passing down within families that
          held longstanding authority in their communities. This role continued into the
          colonial period, where British and Dutch colonial administrations retained the
          position to help manage local governance effectively. While the Vidane system
          has largely faded with modern administrative reforms, it remains a part of Tamil
          and Sinhalese cultural memory as a symbol of localized governance and
          leadership.
        </Typography>
      </Paper>
    </div>
  );
}

export default Notes;
