import React from "react";
import { useLocation, Outlet, Navigate } from "react-router-dom";

import { useAuth, AuthNavigation } from "../context/AuthContext";

const ProtectedRoute = () => {
  const location = useLocation();
  const path = location.pathname;
  const { isAuthenticated } = useAuth();

  if (!isAuthenticated) {
    //console.log("Not logged in. Requested path: " + path);
    AuthNavigation.setRequestedPath(path);
  }
  return isAuthenticated ? <Outlet /> : <Navigate to="/login" />;
};

export default ProtectedRoute;
