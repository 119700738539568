import React from 'react';
import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { grey } from '@mui/material/colors';

const useStyles = makeStyles((theme) => ({
    root: {
        height: '100%',
        color: grey[500],
    },
}));

function Browse(props) {

    const classes = useStyles();
    return (
        <div className={classes.root}>
            <Typography variant="h4" >
                Browse
            </Typography>
        </div>
    );
}

export default Browse;