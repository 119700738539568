import axios from "axios";
import { getApiUrl } from "../utils/network-utils";
import { ResponseCodes } from "../utils/network-utils";

class AuthService {
  // Async function to request a new access and refresh token
  // from the backend
  static async requestRefresh() {
    try {
      // API endpoint to get a new access token if a refresh token is present
      const apiUrl = getApiUrl() + "auth/refresh";

      const response = await axios.get(apiUrl, {
        withCredentials: true, // Enable sending cookies for CORS
      });

      // Check the response status after the request is complete
      if (response.data.status_code === ResponseCodes.SUCCESS) {
        return true;
      } else {
        console.error("Error: " + response.data.status_description);
        return false;
      }
    } catch (error) {
      console.error("Error requesting new token: ", error.message);
      return false;
    }
  }
}

export default AuthService;
