import React, { useEffect, useState } from "react";
import { Typography, Paper, Grid } from "@mui/material";
import ReactGA from "react-ga4";
import { makeStyles } from "@mui/styles";
import { getApiUrl, ResponseCodes } from "../utils/network-utils";
import axios from "axios";
import useCustomStyles from "../styles/customStyles";

const useStyles = makeStyles((theme) => ({
  dashoboardPaper: {
    textAlign: "center",
    elevation: "3",
    padding: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
}));

function Dashboard({ children }) {
  const classes = useStyles();
  const [stats, setStats] = useState();

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname + window.location.search,
      title: "Dashboard",
    });
    const fetchStats = async () => {
      // Calculate the date one week ago (subtracting 7 days)
      const currentDate = new Date();
      const oneWeekAgo = new Date(currentDate.getTime() - 7 * 24 * 60 * 60 * 1000);
      // Convert the oneWeekAgo date to Unix time (milliseconds since the Unix epoch)
      const unixTimeOneWeekAgo = oneWeekAgo.getTime();
      const dashApiUrl = getApiUrl() + "stats/get";
      const params = new URLSearchParams([["since", unixTimeOneWeekAgo]]);

      axios
        .get(dashApiUrl, { params, withCredentials: true })
        .then((response) => {
          if (response?.data?.status_code === ResponseCodes.SUCCESS) {
            var data = response.data.data;
            setStats(data);
            //console.log("Stats data: " + JSON.stringify(data));
          }
        })
        .catch((error) => {
          // Handle errors
          console.error("Error fetching data:", error);
        });
    };

    fetchStats();
  }, []);

  function getSquare(title, value) {
    return (
      <Grid item xs={12} md={3}>
        <Paper className={classes.dashoboardPaper}>
          <div>
            <Typography variant="heading2">{title}</Typography>
          </div>
          <div>
            <Typography variant="heading1">{value}</Typography>
          </div>
        </Paper>
      </Grid>
    );
  }
  const customClasses = useCustomStyles();
  return (
    <div className={customClasses.root}>
      {stats && (
        <Grid container spacing={2}>
          {getSquare("UPDATES SINCE LAST WEEK", stats.update_count)}
          {getSquare("INDIVIDUALS", stats.individual_count)}
          {getSquare("FAMILIES", stats.family_count)}
          {getSquare("PHOTOS", stats.photo_count)}
        </Grid>
      )}
      {children}
    </div>
  );
}

export default Dashboard;
