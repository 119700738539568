import React, { useState } from "react";
import { TextField } from "@mui/material";

const YearField = ({ label, value, onChangeHandler, onChangeLabel, setErrors }) => {
  const formattedLabel = label.toLowerCase().replace(/\s+/g, "-");

  const [error, setError] = useState("");
  const [yearValue, setYearValue] = useState(value);

  const handleYearChange = (event) => {
    const inputValue = event.target.value;
    setYearValue(inputValue);

    const isValidInput = /^\d+$/.test(inputValue);
    const currentYear = new Date().getFullYear();

    // Validate the input and set the error state accordingly
    if (isValidInput || inputValue === "") {
      const intValue = parseInt(inputValue, 10);
      const currentYear = new Date().getFullYear();

      if ((intValue >= 0 && intValue <= currentYear) || inputValue === "") {
        setError("");
        onChangeHandler(onChangeLabel, inputValue);
        setErrors((prevErrors) => prevErrors.filter((err) => err !== label));
      } else {
        setError("Year must be a positive integer less than " + (currentYear + 1));
        setErrors((prevErrors) => [...new Set([...prevErrors, label])]);
      }
    } else {
      setError("Year must be a positive integer less than " + (currentYear + 1));
      setErrors((prevErrors) => [...new Set([...prevErrors, label])]);
    }
  };

  return (
    <TextField
      variant="outlined"
      id={formattedLabel}
      onChange={handleYearChange}
      label={label}
      value={yearValue}
      error={Boolean(error)}
      helperText={error}
    />
  );
};

export default YearField;
