import React, { useEffect, useState, useCallback } from "react";
import { useParams } from "react-router-dom";
import { Typography, Paper } from "@mui/material";
import { makeStyles } from "@mui/styles";
import ReactGA from "react-ga4";
import axios from "axios";
import { ResponseCodes, getApiUrl } from "../utils/network-utils";
import useCustomStyles from "../styles/customStyles";
import { useNavigate } from "react-router-dom";
import {
  ReactFlow,
  MiniMap,
  Controls,
  Background,
  useNodesState,
  useEdgesState,
} from "@xyflow/react";
import "@xyflow/react/dist/style.css";
import dagre from "dagre";

const useStyles = makeStyles((theme) => ({
  individualPaper: {
    width: "95%",
    position: "relative",
    padding: theme.spacing(3),
    //textAlign: 'center',
    alignItems: "center",
    overflow: "hidden", // Hide overflowing content
    marginBottom: theme.spacing(3),
  },
  individualInfo: {
    fontSize: "4rem",
    position: "relative",
    textAlign: "center",
  },
  name: {
    fontSize: "1.7rem",
    fontWeight: "575",
  },
  prefix: {
    fontSize: "1.7rem",
    fontWeight: "450",
    color: theme.palette.prefix.main,
  },
  pedigreeChart: {
    width: "100%",
    height: "35rem",
  },
  "@media (max-width: 800px)": {
    individualPaper: {
      width: "100%",
    },
    background: {
      height: "100%",
    },
    nodeName: {
      fontSize: theme.spacing(3),
    },
    prefix: {
      fontSize: theme.spacing(3),
    },
  },
}));

// Define colors for genders
const femaleStyles = {
  backgroundColor: "lightpink",
  border: "2px solid deeppink",
  cursor: "pointer",
};

const maleStyles = {
  backgroundColor: "lightblue",
  border: "2px solid dodgerblue",
  cursor: "pointer",
};

function getFullName(data) {
  // Destructure the relevant fields from the data object
  const { name_prefix, first_name, middle_name, last_name, name_suffix } = data;

  // Concatenate only non-empty values with a space between
  return [name_prefix, first_name, middle_name, last_name, name_suffix]
    .filter((part) => part) // Remove any undefined, null, or empty string
    .join(" ");
}

const nodeUrl = "/card/";
function createParentNodes(individualData, nodes, edges, lastLevel) {
  const level = lastLevel + 1;

  if (individualData?.parents?.father) {
    // Create new father node
    nodes.push({
      id: individualData.parents.father.individual_id.toString(),
      data: {
        label: `${getFullName(individualData.parents.father)}`,
        url: `${nodeUrl + individualData.parents.father.individual_id.toString()}`,
      },
      draggable: true,
      style: maleStyles,
    });

    // create edge between father and individual
    edges.push({
      id:
        "father-" +
        individualData.parents.father.individual_id.toString() +
        "-individual-" +
        individualData.individual_id.toString(),
      source: individualData.parents.father.individual_id.toString(),
      target: individualData.individual_id.toString(),
    });

    // create nodes for father's parents
    createParentNodes(individualData.parents.father, nodes, edges, level);
  }

  // create new mother node
  if (individualData?.parents?.mother) {
    nodes.push({
      id: individualData.parents.mother.individual_id.toString(),
      data: {
        label: `${getFullName(individualData.parents.mother)}`,
        url: `${nodeUrl + individualData.parents.mother.individual_id.toString()}`,
      },
      draggable: true,
      style: femaleStyles,
    });

    // create edge between mother and individual
    edges.push({
      id:
        "mother-" +
        individualData.parents.mother.individual_id.toString() +
        "-individual-" +
        individualData.individual_id.toString(),
      source: individualData.parents.mother.individual_id.toString(),
      target: individualData.individual_id.toString(),
    });

    // create nodes for mother's parents
    createParentNodes(individualData.parents.mother, nodes, edges, level);
  }
}

function Pedigree() {
  const classes = useStyles();
  const customClasses = useCustomStyles();
  const { initialId } = useParams();
  const navigate = useNavigate();
  const [individual, setIndividual] = useState();
  const [individualId, setIndividualId] = useState(initialId);

  // Initialize nodes and edges with React Flow v11 hooks
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);

  const handleNodeClick = (event, node) => {
    const url = node.data.url;
    if (url) {
      navigate(url);
    }
    const nodeId = parseInt(node.id, 10); // Ensure ID is an integer
    setIndividualId(nodeId);
  };

  const generatePedigreeChart = (individual) => {
    const nodes = [];
    const edges = [];
    const level = 0;

    // Define nodes with React Flow's new state structure
    nodes.push({
      id: individual.individual_id.toString(),
      data: {
        label: `${getFullName(individual)}`,
        url: `${nodeUrl + individual.individual_id.toString()}`,
      },
      draggable: true,
      style: individual.gender === "female" ? femaleStyles : maleStyles,
    });

    createParentNodes(individual, nodes, edges, level);

    return { nodes, edges };
  };

  const layoutNodes = (nodes, edges) => {
    const g = new dagre.graphlib.Graph();
    g.setGraph({});
    g.setDefaultEdgeLabel(() => ({}));

    // Add nodes to the graph
    nodes.forEach((node) => {
      g.setNode(node.id, { width: 100, height: 50 }); // Set node dimensions
    });

    // Add edges to the graph
    edges.forEach((edge) => {
      g.setEdge(edge.source, edge.target);
    });

    // Run the layout algorithm
    dagre.layout(g);

    // Update node positions based on the layout
    const positionedNodes = nodes.map((node) => {
      const { x, y } = g.node(node.id);
      return { ...node, position: { x, y } };
    });

    return positionedNodes;
  };

  // Get individual details
  const fetchIndividualData = useCallback(
    async (id) => {
      //console.log(`API HTTP - fetch data for individual ${id}`);
      // Define the API URL
      const apiUrl = getApiUrl() + "individual/pedigree";
      const params = new URLSearchParams([["id", id]]);

      axios
        .get(apiUrl, { params, withCredentials: true })
        .then((response) => {
          if (response?.data?.status_code === ResponseCodes.SUCCESS) {
            var data = response.data.data[0];
            setIndividual(data);
            // setIndividualId(data.individual_id);

            // Generate nodes and edges without directly modifying them
            const { nodes: newNodes, edges: newEdges } = generatePedigreeChart(data);

            const positionedNodes = layoutNodes(newNodes, newEdges);
            setNodes(positionedNodes);
            setEdges(newEdges); // Set the edges state
            // console.log("Pedigree data: " + JSON.stringify(data));
          }
        })
        .catch((error) => {
          // Handle errors
          console.error("Error fetching data:", error);
        });
    },
    [setNodes, setEdges]
  );

  useEffect(() => {
    fetchIndividualData(individualId);

    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname + window.location.search,
      title: "Pedigree",
    });
  }, [individualId, fetchIndividualData]);

  return (
    <div>
      {individual && (
        <div className={customClasses.root}>
          <Paper className={customClasses.headingPaper}>
            <div className={classes.individualInfo}>
              <Typography variant="h5">
                <span className={classes.prefix}>{individual.name_prefix}</span>
                <span className={classes.name}>
                  {" "}
                  {individual.first_name} {individual.middle_name} {individual.last_name}
                </span>{" "}
                <span className={classes.prefix}>{individual.name_suffix}</span>
                {individual.alias && (
                  <span className={classes.prefix}> ({individual.alias})</span>
                )}
              </Typography>
            </div>
          </Paper>
          <Paper className={customClasses.contentPaper}>
            <div className={classes.pedigreeChart}>
              <ReactFlow
                nodes={nodes}
                edges={edges}
                onNodesChange={onNodesChange}
                onEdgesChange={onEdgesChange}
                onNodeClick={handleNodeClick}
                grid={false}
                hideAttribution={true}
                fitView
              >
                <MiniMap />
                <Controls />
                <Background />
              </ReactFlow>
            </div>
          </Paper>
        </div>
      )}
    </div>
  );
}

export default Pedigree;
