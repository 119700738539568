import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import useCustomStyles from "../styles/customStyles";
import { Paper, Typography } from "@mui/material";
import axios from "axios";
import CustomTimeline from "../components/CustomTimeline";
import { ResponseCodes, getApiUrl } from "../utils/network-utils";
import ReactGA from "react-ga4";
import { AccountTree as PedigreeIcon } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  individualContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  individualPaper: {
    width: "95%",
    position: "relative",
    padding: theme.spacing(3),
    //textAlign: 'center',
    alignItems: "center",
    overflow: "hidden", // Hide overflowing content
    marginBottom: theme.spacing(3),
  },
  individualInfo: {
    fontSize: "4rem",
    position: "relative",
    textAlign: "center",
  },
  name: {
    fontSize: "1.7rem",
    fontWeight: "575",
  },
  prefix: {
    fontSize: "1.7rem",
    fontWeight: "450",
    color: theme.palette.prefix.main,
  },
  "@media (max-width: 800px)": {
    individualPaper: {
      width: "100%",
    },
    name: {
      fontSize: theme.spacing(3),
    },
    prefix: {
      fontSize: theme.spacing(3),
    },
  },
}));

function Card() {
  const classes = useStyles();
  const customClasses = useCustomStyles();
  const [individual, setIndividual] = useState();
  const { initialId } = useParams();
  const [individualId, setIndividualId] = useState(initialId);

  // Get individual details
  const fetchIndividualData = async (id) => {
    //console.log(`API HTTP - fetch data for individual ${id}`);
    // Define the API URL
    const apiUrl = getApiUrl() + "individual/get";
    const params = new URLSearchParams([["id", id]]);

    axios
      .get(apiUrl, { params, withCredentials: true })
      .then((response) => {
        if (response?.data?.status_code === ResponseCodes.SUCCESS) {
          var data = response.data.data[0];
          setIndividual(data);
          setIndividualId(data.indiviual_id);
          //console.log("Individual data: " + JSON.stringify(data));
        }
      })
      .catch((error) => {
        // Handle errors
        console.error("Error fetching data:", error);
      });
  };

  useEffect(() => {
    fetchIndividualData(individualId);

    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname + window.location.search,
      title: "Card",
    });
  }, [individualId]);

  return (
    <div>
      {individual && (
        <div className={classes.individualContainer}>
          <Paper className={customClasses.headingPaper}>
            <div className={classes.individualInfo}>
              <Typography variant="h5">
                <span className={classes.prefix}>{individual.name_prefix}</span>
                <span className={classes.name}>
                  {" "}
                  {individual.first_name} {individual.middle_name} {individual.last_name}
                </span>{" "}
                <span className={classes.prefix}>{individual.name_suffix}</span>
                {individual.alias && (
                  <span className={classes.prefix}> ({individual.alias})</span>
                )}{" "}
                <IconButton
                  component={Link}
                  to={`/pedigree/${individual.individual_id}`}
                  aria-label="Pedigree Chart"
                >
                  <PedigreeIcon />
                </IconButton>
              </Typography>
            </div>
          </Paper>

          <CustomTimeline
            individual={individual}
            fetchIndividualData={fetchIndividualData}
          />
        </div>
      )}
    </div>
  );
}

export default Card;
