// This file should contain utility functions related to networking

export function getApiUrl() {
  var url = "";
  const DEV = "development";
  const PROD = "production";

  switch (process.env.NODE_ENV) {
    default:
    case DEV:
      url = "http://localhost/api/index.php/";
      break;
    case PROD:
      url = "https://www.ceylontamils.com/api/index.php/";
      break;
  }
  return url;
}

export class ResponseCodes {
  static FAIL_UNKNOWN_ERROR = 0;
  static SUCCESS = 1;
  static FAIL_INVALID_REQUEST = 2;
  static FAIL_MISSING_PARAMETER = 3;
  static FAIL_INVALID_PARAMETER = 4;
  static FAIL_NOT_FOUND = 5;
  static FAIL_USER_LOCKED_OUT = 6;
  static FAIL_USER_DISABLED = 7;
  static FAIL_INVALID_TOKEN = 8;
  static FAIL_UNAUTHORIZED = 9;

  static getMessage(code) {
    switch (code) {
      case ResponseCodes.SUCCESS:
        return "Success";
      case ResponseCodes.FAIL_UNKNOWN_ERROR:
        return "Unknown error";
      case ResponseCodes.FAIL_INVALID_REQUEST:
        return "Invalid request";
      case ResponseCodes.FAIL_MISSING_PARAMETER:
        return "Missing username or password.";
      case ResponseCodes.FAIL_INVALID_PARAMETER:
        return "Invalid parameter";
      case ResponseCodes.FAIL_NOT_FOUND:
        return "Invalid username or password.";
      case ResponseCodes.FAIL_USER_LOCKED_OUT:
        return "Too many failed login attempts. User locked out.";
      case ResponseCodes.FAIL_USER_DISABLED:
        return "User disabled";
      case ResponseCodes.FAIL_INVALID_TOKEN:
        return "Invalid token";
      case ResponseCodes.FAIL_UNAUTHORIZED:
        return "Unauthorized";
      default:
        return "Unknown error";
    }
  }
}
