export function isAdmin(isAuthenticated, user) {
  if (!isAuthenticated || !user) return false;

  const rolesArray = user.roles.split(",").map((role) => role.trim()); // trim to remove any extra spaces

  // Check if "administrator" is in the array
  return rolesArray.includes("administrator");
}

export const Roles = Object.freeze({
  AUTHENTICATED_USER: "authenticated_user",
  ADMINISTRATOR: "administrator",
  DATA_MANAGER: "data_manager",
  USER_MANAGER: "user_manager",
});
