import React, { useEffect, useState } from "react";
import { Typography, Paper } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { grey } from "@mui/material/colors";
import { useNavigate, useParams } from "react-router-dom";
import ReactGA from "react-ga4";
import axios from "axios";
import { getApiUrl, ResponseCodes } from "../utils/network-utils";
import EditFamilyForm from "./EditFamilyForm";
import AuthService from "../services/AuthService";
import { detectChanges, getEmptyFamily } from "../utils/data-utils";

const useStyles = makeStyles((theme) => ({
  root: {
    color: grey[500],
    width: "100%",
  },
  heading: {
    marginBottom: "2rem",
  },
  formContainer: {
    width: "95vw",
    display: "relative",
    justifyContent: "center",
    padding: theme.spacing(3),
    backgroundColor: "#FFF",
  },
}));

function EditFamily() {
  const classes = useStyles();
  const navigate = useNavigate();
  const { familyId } = useParams();
  const isEditing = familyId ? true : false;
  const [family, setFamily] = useState();
  const [originalFamily, setOriginalFamily] = useState();

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname + window.location.search,
      title: "EditFamily",
    });

    // Get family details
    const fetchFamilyData = async (id) => {
      // Define the API URL
      const getUrl = getApiUrl() + "family/get";
      const params = new URLSearchParams([["id", id]]);
      //console.log("calling api " + getUrl);

      try {
        const response = await axios.get(getUrl, {
          params,
          withCredentials: true, // Enable sending cookies
        });

        if (response?.data?.status_code === ResponseCodes.SUCCESS) {
          setFamilyData(response.data.data[0]);
        } else {
          console.error("Error: failed to retrieve Family data " + id);
        }
      } catch (error) {
        // Handle errors
        console.error("Error fetching data:", error);
      }
    };

    //setIndividual(getEmptyIndividual());
    if (isEditing) {
      if (!family) {
        fetchFamilyData(familyId);
      }
    } else {
      setFamily(getEmptyFamily());
    }
  }, [familyId, family, isEditing]);

  // This function sets family data retrieved from the API
  // and also creates a copy for comparison later to
  // determine what has changed
  function setFamilyData(data) {
    setFamily(data);
    //console.log("Individual data to edit: \n" + JSON.stringify(data));
    const copiedObject = { ...data };
    setOriginalFamily(copiedObject);
  }

  /**
   * Sends family data to backend to be saved including a
   * list of only the changes
   * @param {*} updatedData
   */
  const handleSave = async (updatedData) => {
    //console.log("handleSave: isEditing: " + isEditing);
    let originalData = originalFamily;
    if (!isEditing) {
      originalData = getEmptyFamily();
      setOriginalFamily(originalData);
    }
    // Send the updated data to the server
    //console.log("Empty Data:", JSON.stringify(getEmptyFamily()));
    //console.log("Original Data:", JSON.stringify(originalFamily));
    //console.log("Updated Data:", JSON.stringify(updatedData));
    //updatedData["private"] = updatedData["private"] ? "Y" : "N";

    const changes = detectChanges(originalData, updatedData);
    const combinedData = { family: updatedData, changes: changes };
    console.log("changes: " + JSON.stringify(changes));

    //console.log("Combined Data:", JSON.stringify(combinedData, null, 2));

    try {
      const hasChanges = Object.keys(changes).length > 0;
      let navId;
      if (hasChanges) {
        if (combinedData.family?.husband_id) {
          navId = combinedData.family.husband_id;
        } else {
          navId = combinedData.family.wife_id;
        }
      } else {
        navigate("/dashboard");
        return;
      }
      const updateUrl = getApiUrl() + (isEditing ? "family/update" : "family/create");
      console.log("combined data: " + JSON.stringify(combinedData));
      console.log("Calling " + updateUrl);
      const response = await axios.post(updateUrl, JSON.stringify(combinedData), {
        withCredentials: true,
      });

      if (response?.data?.status_code === ResponseCodes.SUCCESS) {
        const resultId = response?.data?.data?.family_id;
        console.log("Family ID: " + resultId);
        navigate(`/card/${navId}`);
      } else if (response?.data?.status_code === ResponseCodes.FAIL_UNAUTHORIZED) {
        // If the first post request failed because the user was unauthorized
        // request a new access token before posting again. A new access token will be
        // issued if a valid refresh token is present
        await AuthService.requestRefresh();
        const secondUpdate = await axios.post(updateUrl, JSON.stringify(combinedData), {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        });

        if (secondUpdate?.data?.status_code === ResponseCodes.SUCCESS) {
          const resultId = secondUpdate?.data?.data?.family_id;
          console.log("Family ID: " + resultId);
          navigate(`/card/${navId}`);
        } else {
          console.error("Failed to update family: " + JSON.stringify(combinedData));
        }
      } else {
        console.log("Reponse " + JSON.stringify(response));
      }
    } catch (error) {
      // Handle errors
      console.error("Error:", error);
    }
  };

  return (
    <div>
      {family && (
        <div className={classes.root}>
          <Paper className={classes.formContainer}>
            <div>
              <Typography className={classes.heading} variant="heading1">
                {isEditing ? "Edit" : "Create"} Individual
              </Typography>
            </div>
            <EditFamilyForm
              familyData={family}
              onSave={handleSave}
              isEditing={isEditing}
            />
          </Paper>
        </div>
      )}
    </div>
  );
}

export default EditFamily;
