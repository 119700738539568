// This file should contain utility functions related to dates and times.

import moment from "moment";

export function getFormattedDate(year, month, day) {
  try {
    if (year) {
      // check if the data is a birth year within the past 100 years
      // in which case the year will show up as "after 19XX"
      if (typeof year === "string" && year.startsWith("after")) {
        return year;
      }

      // if the year is defined but either the month or day are not
      // then return just the year
      if (!month || !day) {
        return year;
      }

      // Pad single digit month and day values with an extra zero
      const formatMonth = month < 10 ? "0" + month : month;
      const formatDay = day < 10 ? "0" + day : day;

      const myDate = year + "-" + formatMonth + "-" + formatDay;
      //console.log("Trying to format: " + myDate);
      const incomingFormat = "YYYY-MM-DD";
      const formattedDate = moment(myDate, incomingFormat).format("MMMM D, YYYY");
      return formattedDate;
    }
  } catch (error) {
    console.error("Error fetching data:", error);
  }
  return "unknown date";
}
