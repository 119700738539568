import React, { useState } from "react";
import { Button, Grid, TextField, FormHelperText } from "@mui/material";
import { useNavigate } from "react-router-dom";
import MonthSelect from "../components/MonthSelect";
import DaySelect from "../components/DaySelect";
import YearField from "../components/YearField";
import { useTheme } from "@emotion/react";

const EditFamilyForm = ({ familyData, onSave, isEditing }) => {
  const [errors, setErrors] = useState([]);
  const [formData, setFormData] = useState({
    family_id: familyData.family_id,
    husband_id: familyData.husband_id || "",
    wife_id: familyData.wife_id || "",
    marriage_day: familyData.marriage_day || "",
    marriage_month: familyData.marriage_month || "",
    marriage_year: familyData.marriage_year || "",
    marriage_era: familyData.marriage_era || "CE",
    divorce_day: familyData.divorce_day || "",
    divorce_month: familyData.divorce_month || "",
    divorce_year: familyData.divorce_year || "",
    divorce_era: familyData.divorce_era || "CE",
  });
  const [error, setError] = useState("");
  const [husbandId, setHusbandId] = useState();
  const [wifeId, setWifeId] = useState();
  const navigate = useNavigate();
  const theme = useTheme();
  const handleInputChange = (field, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [field]: value,
    }));

    if (
      (field === "husband_id" && value.length === 0) ||
      (field === "wife_id" && value.length === 0)
    ) {
      setError("Both husband ID and wife ID cannot be empty.");
      setErrors((prevErrors) => [...new Set([...prevErrors, field])]);
    } else {
      setError("");
      setErrors((prevErrors) =>
        prevErrors.filter((err) => err !== "husband_id" && err !== "wife_id")
      );
    }

    if (field === "husband_id") {
      setHusbandId(value);
    } else if (field === "wife_id") {
      setWifeId(value);
    }
  };

  // Validates form data
  const isValidateFormData = () => {
    return errors.length === 0;
  };

  const handleCancel = () => {
    // Navigate back to dashboard
    navigate("/dashboard");
  };
  const handleSave = () => {
    if ((!husbandId || husbandId.length === 0) && (!wifeId || wifeId.length === 0)) {
      const field = husbandId && husbandId.length === 0 ? "husband_id" : "wife_id";
      setError("Both husband ID and wife ID cannot be empty.");
      setErrors((prevErrors) => [...new Set([...prevErrors, field])]);
    }

    if (isValidateFormData()) {
      onSave(formData);
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={3}>
        <TextField
          label="Husband ID"
          value={formData.husband_id}
          onChange={(e) => handleInputChange("husband_id", e.target.value)}
          //error={Boolean(error.length)}
          //helperText={error}
          autoFocus={!isEditing}
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <TextField
          label="Wife ID"
          value={formData.wife_id}
          onChange={(e) => handleInputChange("wife_id", e.target.value)}
        />
      </Grid>
      <Grid item xs={12} md={6} />
      <Grid item xs={12} md={1.5}>
        <DaySelect
          label="Marriage Day"
          value={formData.marriage_day}
          onChange={(event) => handleInputChange("marriage_day", event)}
        />
      </Grid>
      <Grid item xs={12} md={1.5}>
        <MonthSelect
          label="Marriage Month"
          value={formData.marriage_month}
          onChange={(event) => handleInputChange("marriage_month", event)}
        />
      </Grid>
      <Grid item xs={12} md={2}>
        <YearField
          label="Marriage Year"
          value={formData.marriage_year}
          onChangeHandler={handleInputChange}
          onChangeLabel="marriage_year"
          setErrors={setErrors}
        />
      </Grid>
      <Grid item xs={12} md={7} />
      <Grid item xs={12} md={1.5}>
        <DaySelect
          label="Divorce Day"
          value={formData.divorce_day}
          onChange={(event) => handleInputChange("divorce_day", event)}
        />
      </Grid>
      <Grid item xs={12} md={1.5}>
        <MonthSelect
          label="Divorce Month"
          value={formData.divorce_month}
          onChange={(event) => handleInputChange("divorce_month", event)}
        />
      </Grid>
      <Grid item xs={12} md={2}>
        <YearField
          label="Divorce Year"
          value={formData.divorce_year}
          onChangeHandler={handleInputChange}
          onChangeLabel="divorce_year"
          setErrors={setErrors}
        />
      </Grid>
      <Grid item xs={12} md={12}>
        {error && (
          <FormHelperText style={{ color: theme.palette.error.main }}>
            {error}
          </FormHelperText>
        )}
      </Grid>

      <Grid item xs={12}>
        <Button
          variant="contained"
          onClick={handleSave}
          style={{ marginRight: "1rem" }}
          disabled={!isValidateFormData()}
        >
          {isEditing ? "Save" : "Create"}
        </Button>
        <Button variant="contained" color="secondary" onClick={handleCancel}>
          Cancel
        </Button>
      </Grid>
    </Grid>
  );
};

export default EditFamilyForm;
