import React from "react";
import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";

const CustomSelect = ({ label, value, options, onChange }) => {
  const formatLabel = (label) => {
    return label.toLowerCase().replace(/\s+/g, "-");
  };

  const formattedLabel = formatLabel(label);
  const initialValue = value ?? "";
  return (
    <FormControl sx={{ minWidth: 140 }}>
      <InputLabel style={{ marginTop: "1.25rem" }} id={`${formattedLabel}-select-label`}>
        {label}
      </InputLabel>
      <Select
        labelId={`${formattedLabel}-select-label`}
        id={`${formattedLabel}-select`}
        value={initialValue}
        label={label}
        onChange={(e) => onChange(e.target.value)}
      >
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default CustomSelect;
