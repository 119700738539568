import React, { useEffect, useState } from "react";
import axios from "axios";
import { makeStyles } from "@mui/styles";
import { ResponseCodes, getApiUrl } from "../utils/network-utils";
import useCustomStyles from "../styles/customStyles";

import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableSortLabel,
  Typography,
  Paper,
  useMediaQuery,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  heading: {
    marginBottom: theme.spacing(2),
  },
  auditResults: {
    width: "95%",
    padding: theme.spacing(3),
  },
  list: {
    //maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
    position: "relative",
    overflow: "auto",
    maxHeight: 500,
  },
  link: {
    backgroundColor: "inherit",
    padding: 0,
    textDecoration: "none",
  },
  hoverRow: {
    "&:hover": {
      backgroundColor: theme.palette.hoverRow.main,
    },
    cursor: "pointer",
  },
  headRow: {
    backgroundColor: theme.palette.headerRow,
  },
}));

function AuditSection() {
  const classes = useStyles();
  const customClasses = useCustomStyles();
  const [auditResults, setAuditResults] = useState([]);
  const [tableData, setTableData] = useState([]);
  // Use the useMediaQuery hook to check if the screen size is small (e.g., mobile)
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));

  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("id");

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const stableSort = (array, comparator) => {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  };

  const getComparator = (order, orderBy) => {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  };

  const descendingComparator = (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) return -1;
    if (b[orderBy] > a[orderBy]) return 1;
    return 0;
  };

  const navigate = useNavigate();

  // Navigate to the card page specific to an individual
  // when a row in the table is clicked
  const handleRowClick = (id) => {
    navigate(`/card/${id}`);
    // Scroll to the top of the page when the new page loads
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    const MAX_AUDIT_RECORDS = 10;
    const apiUrl = getApiUrl() + "audit/get";
    const params = new URLSearchParams([["limit", MAX_AUDIT_RECORDS]]);

    const fetchAuditData = async () => {
      //console.log("Calling API: " + apiUrl);
      await axios
        .get(apiUrl, {
          params,
          withCredentials: true, // Enable sending cookies
        })
        .then((response) => {
          if (response?.data?.status_code === ResponseCodes.SUCCESS) {
            //console.log("response data: " + JSON.stringify(response.data.data));
            setAuditResults(response.data.data);

            const tempData = response?.data?.data
              ? response.data.data.map((item, index) => ({
                  audit_id: item.audit_id,
                  table_name: item.table_name,
                  column_name: item.column_name,
                  record_id: item.record_id,
                  old_value: item.old_value,
                  new_value: item.new_value,
                  user_email: item.user_email,
                  update_time: item.update_time,
                }))
              : [];

            setTableData(tempData);
          }
        });
    };

    fetchAuditData();
  }, []);

  const headCells = [
    { id: "table_name", label: "Table" },
    { id: "column_name", label: "Column Name" },
    { id: "record_id", label: "ID" },
    { id: "old_value", label: "Old Value" },
    { id: "new_value", label: "New Value" },
    { id: "user_email", label: "User Email" },
    { id: "update_time", label: "Timestamp" },
  ];

  return (
    <div className={customClasses.root}>
      {!auditResults && (
        <Paper className={customClasses.contentPaper}>
          <Typography variant="h6">Not audit results.</Typography>
        </Paper>
      )}

      {tableData.length > 0 && (
        <Paper className={customClasses.contentPaper}>
          <Typography variant="h6" style={{ marginBottom: "1rem" }}>
            Recent Updates
          </Typography>
          <TableContainer>
            <Table stickyHeader size="small">
              <TableHead>
                <TableRow className={classes.headRow}>
                  {headCells.map((headCell) => {
                    if (
                      !isMobile ||
                      (isMobile &&
                        (headCell.id === "table_name" || headCell.id === "user_email"))
                    ) {
                      return (
                        <TableCell
                          key={headCell.id}
                          sortDirection={orderBy === headCell.id ? order : false}
                        >
                          <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : "asc"}
                            onClick={() => handleRequestSort(headCell.id)}
                          >
                            {headCell.label}
                          </TableSortLabel>
                        </TableCell>
                      );
                    } else return null;
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {stableSort(tableData, getComparator(order, orderBy)).map((row) => (
                  <TableRow className={classes.hoverRow} key={row.audit_id}>
                    <TableCell onClick={() => handleRowClick(row.record_id)}>
                      {row.table_name}
                    </TableCell>
                    {!isMobile && (
                      <>
                        <TableCell onClick={() => handleRowClick(row.record_id)}>
                          {row.column_name}
                        </TableCell>
                        <TableCell onClick={() => handleRowClick(row.record_id)}>
                          {row.record_id}
                        </TableCell>
                        <TableCell onClick={() => handleRowClick(row.record_id)}>
                          {row.old_value}
                        </TableCell>
                        <TableCell onClick={() => handleRowClick(row.record_id)}>
                          {row.new_value}
                        </TableCell>
                      </>
                    )}
                    <TableCell onClick={() => handleRowClick(row.record_id)}>
                      {row.user_email}
                    </TableCell>
                    {!isMobile && (
                      <TableCell onClick={() => handleRowClick(row.record_id)}>
                        {row.update_time}
                      </TableCell>
                    )}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      )}
    </div>
  );
}

export default AuditSection;
