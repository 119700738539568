import React, { useEffect, useState } from "react";
import { Typography, Paper } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { grey } from "@mui/material/colors";
import { useNavigate, useParams } from "react-router-dom";
import ReactGA from "react-ga4";
import axios from "axios";
import { getApiUrl, ResponseCodes } from "../utils/network-utils";
import EditIndividualForm from "./EditIndividualForm";
import AuthService from "../services/AuthService";
import { detectChanges, getEmptyIndividual } from "../utils/data-utils";

const useStyles = makeStyles((theme) => ({
  root: {
    color: grey[500],
    width: "100%",
  },
  heading: {
    marginBottom: "2rem",
  },
  formContainer: {
    width: "95vw",
    display: "relative",
    justifyContent: "center",
    padding: theme.spacing(3),
    backgroundColor: "#FFF",
  },
}));

function EditIndividual() {
  const classes = useStyles();
  const navigate = useNavigate();
  const { individualId } = useParams();
  const [individual, setIndividual] = useState();
  const [originalIndividual, setOriginalIndividual] = useState();
  const isEditing = individualId ? true : false;

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname + window.location.search,
      title: "EditIndividual",
    });

    // Get individual details
    const fetchIndividualData = async (id) => {
      // Define the API URL
      const getUrl = getApiUrl() + "individual/get";
      const params = new URLSearchParams([["id", id]]);
      //console.log("calling api " + getUrl);

      try {
        const response = await axios.get(getUrl, {
          params,
          withCredentials: true, // Enable sending cookies
        });

        if (response?.data?.status_code === ResponseCodes.SUCCESS) {
          setIndividualData(response.data.data[0]);
        } else {
          console.error("Error: failed to retrieve individual data " + id);
        }
      } catch (error) {
        // Handle errors
        console.error("Error fetching data:", error);
      }
    };

    //setIndividual(getEmptyIndividual());
    if (isEditing) {
      if (!individual) {
        fetchIndividualData(individualId);
      }
    } else {
      setIndividual(getEmptyIndividual());
    }
  }, [individualId, individual, isEditing]);

  // This function sets individual data retrieved from the API
  // and also creates a copy for comparison later to
  // determine what has changed
  function setIndividualData(data) {
    setIndividual(data);
    //console.log("Individual data to edit: \n" + JSON.stringify(data));
    const copiedObject = { ...data };
    setOriginalIndividual(copiedObject);
  }

  /**
   * Sends individual data to backend to be saved including a
   * list of only the changes
   * @param {*} updatedData
   */
  const handleSave = async (updatedData) => {
    //console.log("handleSave: isEditing: " + isEditing);
    let originalData = originalIndividual;
    if (!isEditing) {
      originalData = getEmptyIndividual();
      setOriginalIndividual(originalData);
    }
    // Send the updated data to the server
    //console.log("Empty Data:", JSON.stringify(getEmptyIndividual()));
    //console.log("Original Data:", JSON.stringify(originalIndividual));
    //console.log("Updated Data:", JSON.stringify(updatedData));
    updatedData["private"] = updatedData["private"] ? "Y" : "N";
    updatedData["deceased"] = updatedData["deceased"] ? "Y" : "N";

    const changes = detectChanges(originalData, updatedData);
    const combinedData = { individual: updatedData, changes: changes };
    console.log("changes: " + JSON.stringify(changes));
    console.log("changes: " + JSON.stringify(combinedData));

    //console.log("Combined Data:", JSON.stringify(combinedData, null, 2));

    try {
      const updateUrl =
        getApiUrl() + (isEditing ? "individual/update" : "individual/create");
      //console.log("combined data: " + JSON.stringify(combinedData));
      const response = await axios.post(updateUrl, JSON.stringify(combinedData), {
        withCredentials: true,
      });

      if (response?.data?.status_code === ResponseCodes.SUCCESS) {
        const resultId = response?.data?.data?.individual_id;
        navigate(`/card/${resultId}`);
      } else if (response?.data?.status_code === ResponseCodes.FAIL_UNAUTHORIZED) {
        // If the first post request failed because the user was unauthorized
        // request a new access token before posting again. A new access token will be
        // issued if a valid refresh token is present
        await AuthService.requestRefresh();
        const secondUpdate = await axios.post(updateUrl, JSON.stringify(combinedData), {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        });

        if (secondUpdate?.data?.status_code === ResponseCodes.SUCCESS) {
          const resultId = secondUpdate?.data?.data?.individual_id;
          navigate(`/card/${resultId}`);
        } else {
          console.error("Failed to update individual: " + JSON.stringify(combinedData));
        }
      }
    } catch (error) {
      // Handle errors
      console.error("Error:", error);
    }
  };

  return (
    <div>
      {individual && (
        <div className={classes.root}>
          <Paper className={classes.formContainer}>
            <div>
              <Typography className={classes.heading} variant="heading1">
                {isEditing ? "Edit" : "Create"} Individual
              </Typography>
            </div>
            <EditIndividualForm
              individualData={individual}
              onSave={handleSave}
              isEditing={isEditing}
            />
          </Paper>
        </div>
      )}
    </div>
  );
}

export default EditIndividual;
