import React, { useState, forwardRef } from "react";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableSortLabel,
  Paper,
  useMediaQuery,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useNavigate, Link } from "react-router-dom";
import { IconButton } from "@mui/material";
import {
  AccountTree as PedigreeIcon,
  PersonSearch as ViewIcon,
  Edit as EditIcon,
} from "@mui/icons-material";

const useStyles = makeStyles((theme) => ({
  hoverRow: {
    "&:hover": {
      backgroundColor: theme.palette.hoverRow.main,
    },
    cursor: "pointer",
  },
  headRow: {
    backgroundColor: theme.palette.headerRow,
  },
}));

const BasicTableWithSort = forwardRef((props, ref) => {
  // Use the useMediaQuery hook to check if the screen size is small (e.g., mobile)
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const isUserAdmin = props.isUserAdmin;
  const classes = useStyles();
  const tableData = props.tableData;
  //console.log(JSON.stringify(tableData));
  const headCells = props.headCells;

  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("id");

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const stableSort = (array, comparator) => {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  };

  const getComparator = (order, orderBy) => {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  };

  const descendingComparator = (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) return -1;
    if (b[orderBy] > a[orderBy]) return 1;
    return 0;
  };

  const navigate = useNavigate();

  // Navigate to the card page specific to an individual
  // when a row in the table is clicked
  const handleRowClick = (id) => {
    navigate(`/card/${id}`);
    // Scroll to the top of the page when the new page loads
    window.scrollTo(0, 0);
  };

  return (
    <Paper style={{ width: "100%" }}>
      <TableContainer ref={ref}>
        <Table stickyHeader size="medium">
          <TableHead>
            <TableRow className={classes.headRow}>
              {headCells.map((headCell) => {
                if (
                  !isMobile ||
                  (isMobile &&
                    (headCell.id === "first_name" || headCell.id === "last_name"))
                ) {
                  return (
                    <TableCell
                      key={headCell.id}
                      sortDirection={orderBy === headCell.id ? order : false}
                    >
                      <TableSortLabel
                        active={orderBy === headCell.id}
                        direction={orderBy === headCell.id ? order : "asc"}
                        onClick={() => handleRequestSort(headCell.id)}
                      >
                        {headCell.label}
                      </TableSortLabel>
                    </TableCell>
                  );
                } else return null;
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {stableSort(tableData, getComparator(order, orderBy)).map((row) => (
              <TableRow className={classes.hoverRow} key={row.id}>
                {!isMobile && (
                  <TableCell onClick={() => handleRowClick(row.id)}>
                    {row.name_prefix}
                  </TableCell>
                )}
                <TableCell onClick={() => handleRowClick(row.id)}>
                  {row.first_name}
                </TableCell>
                {!isMobile && (
                  <TableCell onClick={() => handleRowClick(row.id)}>
                    {row.middle_name}
                  </TableCell>
                )}
                <TableCell onClick={() => handleRowClick(row.id)}>
                  {row.last_name}
                </TableCell>
                {!isMobile && (
                  <>
                    <TableCell onClick={() => handleRowClick(row.id)}>
                      {row.alias}
                    </TableCell>
                    <TableCell onClick={() => handleRowClick(row.id)}>
                      {row.date_of_birth}
                    </TableCell>

                    <TableCell>
                      <IconButton
                        component={Link}
                        to={`/card/${row.id}`}
                        aria-label="View"
                      >
                        <ViewIcon />
                      </IconButton>{" "}
                      <IconButton
                        component={Link}
                        to={`/pedigree/${row.id}`}
                        aria-label="Pedigree Chart"
                      >
                        <PedigreeIcon />
                      </IconButton>{" "}
                      {isUserAdmin && (
                        <IconButton
                          component={Link}
                          to={`/edit-individual/${row.id}`}
                          aria-label="Edit"
                        >
                          <EditIcon />
                        </IconButton>
                      )}
                    </TableCell>
                  </>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
});

export default BasicTableWithSort;
