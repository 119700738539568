import React from "react";
import { Route, Routes } from "react-router-dom";
import About from "../public/About";
import Notes from "../public/Notes";
import Browse from "../public/Browse";
import Card from "../public/Card";
import Contact from "../public/Contact";
import Privacy from "../public/Privacy";
import Search from "../public/Search";
import NotFound from "../public/NotFound";
import Login from "../public/Login";
import Redirector from "./Redirector";
import Dashboard from "../admin/Dashboard";
import Logout from "../public/Logout";
import ProtectedRoute from "./ProtectedRoute";
import Pedigree from "../public/Pedigree";
import EditIndividual from "../admin/EditIndividual";
import EditFamily from "../admin/EditFamily";
import AuditSection from "../admin/AuditSection";

const Router = (props) => {
  return (
    <Routes>
      <Route
        exact
        path="/"
        element={
          <Redirector>
            <About />
          </Redirector>
        }
      />
      <Route exact path="/about" element={<About />} />
      <Route exact path="/notes" element={<Notes />} />
      <Route exact path="/contact" element={<Contact />} />
      <Route exact path="/login" element={<Login />} />
      <Route exact path="/logout" element={<Logout />} />
      <Route exact path="/privacy" element={<Privacy />} />

      <Route exact path="/" element={<ProtectedRoute />}>
        <Route
          path="/dashboard"
          element={
            <Dashboard>
              <AuditSection />
              <Search />
            </Dashboard>
          }
        />
        <Route exact path="/edit-individual/:individualId" element={<EditIndividual />} />
        <Route path="/create-individual" element={<EditIndividual />} />

        <Route exact path="/edit-family/:familyId" element={<EditFamily />} />
        <Route path="/create-family" element={<EditFamily />} />

        <Route exact path="/search" element={<Search />} />
        <Route exact path="/browse" element={<Browse />} />
        <Route exact path="/card/:initialId" element={<Card />} />
        <Route exact path="/pedigree/:initialId" element={<Pedigree />} />
      </Route>
      <Route path="not-found" element={<NotFound />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default Router;
